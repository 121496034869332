import React, { useEffect } from 'react';

import Navbar from "./MainNavbar";
import './App.css';
 
function App() {
    
  
    return (
      
      <Navbar />
        
    );
}
 
export default App;